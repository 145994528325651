// Admin.js
import React from 'react';

function Admin() {
  return (
    <div>
      <h1>Admin Page</h1>
      {/* Add your admin page content here */}
    </div>
  );
}

export default Admin;