import './App.css';
import { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import logo from './logo.png';

const PlayState = {
  STOPPED: 'Stopped',
  STARTING: 'Starting',
  STARTED: 'Started',
  STOPPING: 'Stopping'
};

function Player() {

  const [songTitle, setSongTitle] = useState('');
  const [artistName, setArtistName] = useState('');
  const [albumCoverImage, setAlbumCoverImage] = useState(logo);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [duration, setDuration] = useState(180);
  const [progressBarWidth, setProgressBarWidth] = useState(0);
  const [dominant_color, setDominantColor] = useState([0, 0, 0]);
  const [fadeClass, setFadeClass] = useState('');
  const [playState, setPlayState] = useState(PlayState.STOPPED);
  const socketRef = useRef(null);

  const connectWebSocket = (clientId) => {
    const apiRoute = process.env.NODE_ENV === "production" ? "ws" : "develop/ws";
    const uri = `wss://roshnisync.io/${apiRoute}?type=webapp&clientid=${clientId}`;
    const socket = new WebSocket(uri);
    socketRef.current = socket;

    const handleOpen = (event) => {
      console.log('Connected to the server');
    };

    const handleMessage = (event) => {
      console.log('Received:', event.data);
      const data = JSON.parse(event.data);

      if (data.type === 'new_song_update') {
        console.log('received new song update');
        setSongTitle(data.song_name);
        setArtistName(data.artists);
        setFadeClass('fade-out');
        setTimeout(() => {
          setAlbumCoverImage(data.image_url);
          setFadeClass('');
        }, 500);
        setDominantColor(data.dominant_color);
        setElapsedTime(data.elapsed_time);
        setDuration(data.duration);
        setProgressBarWidth((data.elapsed_time / data.duration) * 100);
      } else if (data.type === 'elapsed_time_update') {
        setElapsedTime(data.elapsed_time);
        setProgressBarWidth((data.elapsed_time / duration) * 100);
      } else if (data.status === 'starting') {
        setPlayState(PlayState.STARTING);
      } else if (data.status === 'started') {
        setPlayState(PlayState.STARTED);
      } else if (data.status === 'stopping') {
        setPlayState(PlayState.STOPPING);
        setFadeClass('fade-out');
        setTimeout(() => {
          setAlbumCoverImage(logo);
          setFadeClass('');
        }, 500);
        setDominantColor([0, 0, 0]);
        setSongTitle('');
        setArtistName('');
      } else if (data.status === 'stopped') {
        setPlayState(PlayState.STOPPED);
      }  
    };

    const handleClose = (event) => {
      console.log('Connection closed:', event.code, event.reason);
      if (event.code === 1006) {
          console.log('Attempting to reconnect...');
          setTimeout(connectWebSocket, 1000);
      }
    };

    socket.addEventListener('open', handleOpen);
    socket.addEventListener('message', handleMessage);
    socket.addEventListener('close', handleClose);
    socket.addEventListener('error', (event) => {
        console.error('WebSocket error:', event);
    });

    return () => {
      socket.removeEventListener('open', handleOpen);
      socket.removeEventListener('message', handleMessage);
      socket.removeEventListener('close', handleClose);
      socket.close();
    };
  };

  useEffect(() => {
    const clientId = uuidv4();
    const cleanup = connectWebSocket(clientId);
    return cleanup;
  }, []);

  return (
    <div className="App" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: `rgb(${dominant_color[0]}, ${dominant_color[1]}, ${dominant_color[2]})` }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
        <img className={`album-cover ${fadeClass}`} src={albumCoverImage} alt="logo" />
        <p className="Song-title" style={{ margin: 0, marginTop: 40, marginBottom: 5, color: '#FFF', fontSize: '24px', fontWeight: 'bold' }}> {songTitle} </p>
        <p className="artist-name" style={{ margin: 0, color: '#FFF' }}> {artistName} </p>
        {playState === PlayState.STARTED && (
          <div style={{ width: '100%', height: '10px', backgroundColor: '#777', marginTop: '10px', borderRadius: 5  }}>
            <div style={{ width: `${progressBarWidth}%`, height: '100%', backgroundColor: '#FFFFFF', borderRadius: 5 }}></div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Player;
