// import './App.css';
// import { useState, useEffect } from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Admin from './Admin'; // Import the Admin component
import Player from './Player'; // Import the Admin component
import Remote from './Remote'; // Import the Admin component

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/admin" element={<Admin />} />
        <Route path="/remote" element={<Remote />} />
        <Route path="/" element={<Player />} />
      </Routes>
    </Router>
  );
  
}

export default App;
