import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './Remote.css'; // Import the CSS file for styling
import spotifyLogo from './spotify-logo.png'; // Import the Spotify logo image
import { IoPlaySharp, IoStopSharp } from 'react-icons/io5';
import { MdDownloading } from 'react-icons/md';

const PlayState = {
    STOPPED: 'Stopped',
    STARTING: 'Starting',
    STARTED: 'Started',
    STOPPING: 'Stopping'
};

function Remote() {
    const [connectionStatus, setConnectionStatus] = useState('Disconnected');
    const [mode, setMode] = useState('Spotify Sync');
    const [playState, setPlayState] = useState(PlayState.STOPPED);
    const [brightness, setBrightness] = useState(50);
    const [isOn, setIsOn] = useState(false);
    const socketRef = useRef(null);

    const connectWebSocket = (clientId) => {
        const apiRoute = process.env.NODE_ENV === "production" ? "ws" : "develop/ws";
        const uri = `wss://roshnisync.io/${apiRoute}?type=webapp&clientid=${clientId}`;
        console.log('Connecting to:', uri);
        const socket = new WebSocket(uri);
        socketRef.current = socket;

        const handleOpen = (event) => {
            console.log('Connected to the server');
            setConnectionStatus('Connected');
        };

        const handleMessage = (event) => {
            // console.log('Received:', event.data);
            const message = JSON.parse(event.data);
            console.log('Received:', message);
            if (message.status === 'starting') {
                setPlayState(PlayState.STARTING);
            } else if (message.status === 'started') {
                setPlayState(PlayState.STARTED);
            } else if (message.status === 'stopping') {
                setPlayState(PlayState.STOPPING);
            } else if (message.status === 'stopped') {
                setPlayState(PlayState.STOPPED);
            }
        };

        const handleClose = (event) => {
            console.log('Connection closed:', event.code, event.reason);
            setConnectionStatus('Disconnected');
            if (event.code === 1006) {
                console.log('Attempting to reconnect...');
                setTimeout(() => connectWebSocket(clientId), 1000);
            }
        };

        socket.addEventListener('open', handleOpen);
        socket.addEventListener('message', handleMessage);
        socket.addEventListener('close', handleClose);
        socket.addEventListener('error', (event) => {
            console.error('WebSocket error:', event);
            setConnectionStatus('Disconnected');
        });

        return () => {
            socket.removeEventListener('open', handleOpen);
            socket.removeEventListener('message', handleMessage);
            socket.removeEventListener('close', handleClose);
        };
    };

    useEffect(() => {
        console.log('useEffect called');
        const clientId = uuidv4();
        connectWebSocket(clientId);
    }, []);

    const handleTogglePlay = () => {
        if (playState === PlayState.STOPPED) {
            sendMessage(JSON.stringify({ type: 'state_change_request', action: 'start' }));
            setPlayState(PlayState.STARTING);
        } else if (playState === PlayState.STARTED) {
            sendMessage(JSON.stringify({ type: 'state_change_request', action: 'stop' }));
            setPlayState(PlayState.STOPPING);
        }
    };

    const handleBrightnessChange = (event) => {
        setBrightness(event.target.value);
    };

    const handlePowerButtonClick = () => {
        setIsOn(!isOn);
        console.log('Power button clicked');
    };

    const sendMessage = (message) => {
        if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
            socketRef.current.send(message);
            console.log('Sent:', message);
        } else {
            console.log('WebSocket is not open. Ready state:', socketRef.current.readyState);
        }
    };

    const getPlayButtonIcon = () => {
        switch (playState) {
            case PlayState.STARTED:
                return <IoStopSharp className="icon-large" />;
            case PlayState.STOPPED:
                return <IoPlaySharp className="icon-large" />;
            case PlayState.STARTING:
            case PlayState.STOPPING:
                return <MdDownloading className="icon-large" />;
            default:
                return null;
        }
    };

    return (
        <div className="remote-container">
            <button
                className="power-button"
                onClick={handlePowerButtonClick}
            >
                <span className={`power-icon ${isOn ? 'on' : 'off'}`}>⏻</span>
            </button>
            <h1 className="remote-title">Remote Control</h1>
            <div className="status-container">
                <p className="connection-status">Server Status: {connectionStatus}</p>
                <div className={`status-indicator ${connectionStatus.toLowerCase()}`}></div>
            </div>

            <div className="status-container">
                <p className="connection-status">Mode Status: {playState}</p>
            </div>

            <div className="mode-selector">
                <button className={`mode-button ${mode === 'Spotify Sync' ? 'active' : ''}`} onClick={() => setMode('Spotify Sync')}>
                    <img src={spotifyLogo} alt="Spotify Sync" className="spotify-logo" />
                </button>
                <button className={`mode-button ${mode === 'Design' ? 'active' : ''}`} onClick={() => setMode('Design')}>🎨</button>
                <button className={`mode-button ${mode === 'Static' ? 'active' : ''}`} onClick={() => setMode('Static')}>💡</button>
            </div>
            {mode === 'Spotify Sync' && (
                <div className="spotify-sync">
                    <div className="button-container">
                        <button className="remote-button" onClick={handleTogglePlay}>
                            {getPlayButtonIcon()}
                        </button>
                    </div>
                </div>
            )}
            {mode === 'Design' && (
                <div className="design-mode">
                    <p>Design mode content goes here.</p>
                </div>
            )}
            {mode === 'Static' && (
                <div className="static-mode">
                    <p>Static mode content goes here.</p>
                </div>
            )}
            <div className="brightness-control">
                <input
                    type="range"
                    id="brightness-slider"
                    min="0"
                    max="100"
                    value={brightness}
                    onChange={handleBrightnessChange}
                    style={{ '--brightness': `${brightness}%` }}
                />
                <div className="brightness-symbol">🔆</div>
            </div>
        </div>
    );
}

export default Remote;